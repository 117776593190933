<template>
  <div>
    <b-card>
      <b-tabs
        justified
        content-class="mt-3"
      >
        <b-tab @click="currentActive = 'registration'; isLoadedRegistration = true" title="Registrations">
          <registrations
            v-if="currentActive === 'registration' || isLoadedRegistration"
            key="17879"
            :dashboard="true"
            class="mb-2"
          />
        </b-tab>
        <b-tab @click="currentActive = 'course'; isLoadedCourse = true" title="Courses">
          <courses-list
            v-if="currentActive === 'course' || isLoadedCourse"
            key="2"
            :dashboard="true"
            class="mb-2"
          />
        </b-tab>
        <b-tab @click="currentActive = 'approval'; isLoadedApproval = true" title="Approval list">
          <courses-approval
            v-if="currentActive.active === 'approval' || isLoadedApproval"
            key="3"
            class="mb-2"
          />
        </b-tab>
      </b-tabs>
    </b-card>
    <widgets  />
  </div>
</template>
<script>
import {
  BCard,
  BTabs,
  BTab,
  } from 'bootstrap-vue'
import Widgets from '@/views/teacher/dashboard/Widgets.vue'
import Registrations from '@/views/courses/registrations/registrations-list/RegistrationsList.vue'
import CoursesList from '@/views/courses/admin/courses-list/CoursesList.vue'
import CoursesApproval from '@/views/admin/courses-approval/CoursesList.vue'

export default {
  components: {
    Registrations,
    CoursesList,
    CoursesApproval,
    Widgets,
    BCard,
    BTabs,
    BTab,
  },
  data() {
    return {
      currentActive: 'registration',
      isLoadedRegistration: true,
      isLoadedCourse: false,
      isLoadedApproval: false,
    }
  },
}
</script>
<style scoped>
.tabs ul {
  padding: 0 0 20px 20px;
  width: 100%;
  z-index: 2;
  left: 0;
  top: 0;
  position: absolute;
}
</style>
